/* navbar.css */

img.navbar-icon {
    margin-right: 10px;
}
  
a.nav-link {
    font-size: 20px;
}

button.subscribe-modal-btn {
    color: black;
    text-decoration: none;
    font-size: 20px;
    padding-left: 0px;
  }
  
button.subscribe-modal-btn:hover {
    color: var(--nav-brand-color);
    text-decoration: none;
}

nav.navbar {
    background-color: var(--header-background)
}

a.navbar-brand {
    color: var(--nav-brand-color);
}

button.subscribe-modal-btn {
    color: var(--nav-brand-color);
}

a.nav-link.active {
    color: var(--nav-link-active-color);
}
a.nav-link {
    color: var(--nav-link-color);
}

.navbar-toggler-icon {
    color: var(--nav-link-color);
}

.darkModeToggle {
    margin-top: auto;
}