p.tagline {
    font-size: 1.3rem;
}

img.img-profile {
    max-width: 150px;
    width: 100%;
}

time.recent-posts-time {
    color: var(--recent-posts-time-color);
    font-family: monospace;
}

a.recent-posts-link {
    color: unset;
}

div.home-intro {
    margin-bottom: 40px;
}

div.highlighted-projects-card {
    background-color: var(--highlighted-projects-card-color);
}

p.highlighted-projects-card-text {
    font-size: .9rem;
}

.highlighted-project-button {
    padding: .5rem .6rem;
}

div.highlighted-projects-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

div.favorite-links-container {
    padding-top: 15px;
}
  
ul.favorite-links-ul {
    padding-inline-start: 15px;
}