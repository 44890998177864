/* footer.css */

div.footer-container {
  padding-top: 170px;
}

@media (min-width: 992px) {
  div.footer-container {
    padding-top: 110px;
  }
}

@media (min-width: 1200px) {
  div.footer-container {
    padding-top: 95px;
  }
}

footer.footer {
  position: absolute;
  bottom: 0;
  padding: 20px;
  width: 100%;
  background-color: #f5f5f5;
  text-align: center;
}

div.footer-col {
  display: inline-block;
  text-align: left;
}

img.footer-icon {
  margin-right: 10px;
  margin-bottom: 5px;
}

h3.footer-brand {
  display: inline-block;
  max-width: 80%;
}

footer.footer {
  background-color: var(--header-background);
  color: var(--color);
}