/* index.css */

html, #root, .App {
  height: 100%;
}

/* automatic/manual light mode */
:root, :root.light {
  --background-color: #ffffff;
  --color: #212529;
  --hr-color: #0000001a;
  --text-muted-color: #6c757d;
  --margin-color: #CCCCCC;
  --card-background: #ffffff;
  --modal-background: #fff;
  --header-background: #f8f9fa;
  --nav-brand-color: #000000e6;
  --nav-link-color: #00000080;
  --nav-link-active-color: #000000e6;
  --recent-posts-time-color: #666;
  --highlighted-projects-card-color: #f9f9f9;
}

/* automatic dark mode */
@media (prefers-color-scheme: dark) {
  :root {
    --background-color: #1f1f1f;
    --color: #ffffff;
    --hr-color: #ffffff;
    --text-muted-color: #a8adb2;
    --margin-color: #494949;
    --card-background: #3c3c3c;
    --modal-background: #212529;
    --header-background: #343a40;
    --nav-brand-color: #fff;
    --nav-link-color: #ffffff80;
    --nav-link-active-color: #fff;
    --recent-posts-time-color: #5eddac;
    --highlighted-projects-card-color: #2c2c2c;
  }
}

/* manual dark mode */
:root.dark {
  --background-color: #1f1f1f;
  --color: #ffffff;
  --hr-color: #ffffff;
  --text-muted-color: #a8adb2!important;
  --margin-color: #494949;
  --card-background: #3c3c3c;
  --modal-background: #212529;
  --header-background: #343a40!important;
  --nav-brand-color: #fff!important;
  --nav-link-color: #ffffff80!important;
  --nav-link-active-color: #fff!important;
  --recent-posts-time-color: #5eddac;
  --highlighted-projects-card-color: #2c2c2c;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  font-size: 1.3rem;
  background-color: var(--background-color);
  color: var(--color);
}

div.app-container {
  margin-bottom: 4rem;
}

hr {
  border-top-color: var(--hr-color);
}

.table {
  color: var(--color);
}

.text-muted {
  color: var(--text-muted-color)!important;
}


div.container {
  margin-top: 5%;
  margin-bottom: 5%;
  margin-left: auto;
}

@media (min-width: 768px) {
  div.container {
    width: 50%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
    color: #f8f8f2;
    max-width: 90%;
    display: block;
}

code.inline {
  color: #e83e8c;
  display: inline;
}

.code-block {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace !important;
  color: #f8f8f2 !important;
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .code-block {
    position: relative;
    left: -10%;
    width: 120%;
  }
}

div.code-block button {
  border-width: 0px;
}

div.code-block button {
  border-width: 0px;
}

div.left-margin, div.right-margin {
  background-color: var(--margin-color);
  z-index: -1;
}

div.full-height {
  height: 100%;
}

div.col {
  padding: 0;
}

div.container-headshot {
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 5%;
  margin-bottom: 20px;
}

img.headshot {
  width: 40%;
  min-width: 200px;
}

div.bio {
  max-width: 1000px;
}

div.bio-col {
  padding-left: 10%;
  padding-right: 10%;
}

h1.name-title {
  margin-top: 30px;
}

div.blog-card {
  margin-bottom: 30px;
  margin-right: 15px;
  margin-left: 15px;
  margin-top: 30px;
  padding: 30px;
  align-items:center;
}

img.github-intext {
  height:20px;
  width: 20px;
}


blockquote.blockquote {
  border-left: 10px solid #ccc;
  padding-left: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-style: italic;
}

pre {
  background-color: #212529;
  padding: .5rem .5rem .5rem;
  border-radius: .5rem;
  color: #38fe27;
}

img.card-img-top {
  max-width: 20rem;
}

.card {
  background-color: var(--card-background);
}

.card-columns {
  column-count: 1;
}
@media (min-width: 1200px) {
  .card-columns {
      column-count: 2;
      padding-right: 15px;
  }
}

div.form-card {
  margin: auto;
}

input.crypto-calc-input {
  width: 75%!important;
}

div.crypto-calc-row {
  padding-bottom: 25px;
}

div.tennis-form-container {
	font-size: 14px;
	padding-left: 0px;
	padding-right: 0px;
}

@media (min-width: 1200px) {
	div.tennis-form-container {
		max-width: 75%;
		font-size: 18px;
	}
}

div.modal {
  padding-right: 15px !important;
}

.modal-open {
  overflow: auto;
}

div.modal-content {
  background-color: var(--modal-background)
}


